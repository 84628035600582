.flexrow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.link{
    margin:10px;
    cursor: pointer;
    border: 2px blue solid;
    width: auto;
    flex-basis: 30%;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    background-color: aqua;
    font-weight: bold;
    font-size: 25px;
}
.link:hover{
    background-color:rgb(193, 162, 221);
}